const DAVID_FRIGERI = {
  text: `
    <p>
      Amy - A quick note of appreciation for your contributions to our AI Value Calculator team. Your deep understanding of the challenges our Slalom customers face in calculating and prioritizing ROI for AI use-cases has been invaluable. Your ability to simplify complex problems and provide clear, actionable insights has greatly benefited the effectiveness of the AVC. Additionally, your humble and generous approach to teamwork, always willing to share your time and knowledge, has fostered a supportive and collaborative environment.
    </p>

    <p>
      Your responsiveness to frequent questions about functionality, helping with pursuits, and answering queries on Teams has also been exceptional. Your proactive support has improved our efficiency and reliability, making a noticeable impact on our daily operations. Furthermore, your contributions have resulted in a total AI Calculator pipeline exceeding $10 million with customers such as United, T-Mobile, JM Family, and Comcast, to name a few. Thank you for your dedication and hard work.
    </p>
  `,
  person: {
    name: "David Frigeri",
    title: `Managing Director, AI`
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};

const STEPHEN_NICHOLS = {
  text: `
    <p>
      Amy joined my team to build a new inner source platform for consulting accelerators. Over the course of 2.5 years we have built or published over 60 accelerators, influenced 150M+ revenue in sales, and impacted 200+ client engagements across 40 Slalom markets.
    </p>

    <p>
      Amy is a amazing team member and a joy to work with. She is extremely driven and takes pride in her work. She is one of the few people who are truly "full stack." In the product development space Amy has demonstrated the ability to gather requirements, create detailed designs based on use cases, lead front end engineering efforts, write back end code, write infrastructure as code, build databases, and enforce DevOps practices. She has a tremendous capacity to learn new things including across the tech stack. She is very organized and detail oriented which allows her to be a very effective presenter and communicator.
    </p>

    <p>
      I would endorse Amy in both leadership and deep engineering roles. I hope our paths cross in the future so we can work together again.
    </p>
  `,
  person: {
    name: "Stephen Nichols",
    title: `Director, Software Engineering`,
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};

const RAPH_CASADEI = {
  text: `
    <i>
      After hitting our Google Next MVP deadline
    </i>
    <p>
      I wanted to recognize and thank Amy, Craig and Tony for leading the development of the AI Value Calculator tool for Slalom. This tool will provide a major unlock for Slalom to have outcome driven and impactful client conversations and drive GenAI pipeline for all of Slalom! The team has demonstrated their ability to quickly understand the requirements and build a compelling MVP, while navigating some of the scope ambiguity. In addition, they have worked tirelessly to meet the need to have an MVP ready for demo at the Google Next event the week of 04/08. This team is fueling Slalom's future by developing breakthrough solutions and products and I am thankful I get to collaborate with them! Well-done and thank you!
    </p>

    <i>
      After successfully pitching the idea to a group of Managing Directors
    </i>
    <p>
      Over the last three days I had the pleasure to work with Amy to define, plan, and scope out the build of a Slalom App hosted on <a href="https://www.slalom.com">slalom.com</a>. Amy was able to quickly understand the use case and features we want to build, ask the right questions to gather the information she needs, and quickly turn around amazing wireframes to provide a polished demo to Slalom Sr Execs. In addition Amy, provided her expert opinion on what it will take to build this app, as well as, guidance on potential design trade offs to reduce the development effort. To me, Amy embodies Slalom 5.0, by applying her expertise to create amazing solutions that solves our clients challenges today and in the future. Thank you Amy.
    </p>
  `,
  person: {
    name: "Raph Casadei",
    title: `Senior Director, Global Business Advisory Services`,
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};

const AHAD_HOSSEINI = {
  text: `
    <p>
      I am extraordinarily impressed with Amy Sitwala. In an intense 2-day workshop, Amy was able to develop highly impressive wireframes and architectural considerations with just a few hours of context from a small hyper-focused team.
    </p>

    <p>
      Amy is not only a talented front-end designer but also understands how accelerators should be well-architected. Seeing her quickly grasp deeply analytical concepts and bring them to life was exciting and brought to life a vision that we have been exploring for months.
    </p>

    <p>
      I am excited to see Amy's leadership on full display as we continue to develop this accelerator and get it prepped for Slalom-wide adoption.
    </p>
  `,
  person: {
    name: "Ahad Hosseini",
    title: "Senior Principal, Global Business Advisory Services",
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};

const JASON_MAX = {
  text: `
    <p>
      I had the pleasure of working with Amy on the GenAI Tested for AWS accelerator project.
    </p>

    <p>
      Amy - Your remarkable efforts have not gone unnoticed. I wanted to express my gratitude and appreciation for your outstanding contributions to the team. Your dedication, expertise, and collaborative spirit made a significant impact on the project.
    </p>

    <p>
      Your ability to take charge of the frontend/UI development effort right away, despite joining the team after its formation, was very impressive. You exhibited a proactive approach and initiative in assuming this responsibility and it significantly elevated the project timelines and overall quality.
    </p>

    <p>
      You were always willing to support and guide others in understanding concepts or resolving issues, which fostered a more collaborative and knowledgeable team environment. Your eagerness to share your wealth of knowledge and skill with other team members was invaluable.
    </p>

    <p>
      The commitment you displayed to the project was commendable. You were dedicated to meet deadlines, ensuring high-quality deliverables, and going above and beyond expectations, reflecting your strong work ethic and professionalism. The consistent delivery of high-quality work speaks volumes about your experience and attention to detail. Your contributions consistently raised the bar for our project standards and were instrumental in our team's success, and we are incredibly fortunate to have you as part of the team.
    </p>

    <p>
      Please continue to bring forth your innovative ideas, dedication and expertise as it sets a great example for everyone. Excellent work Amy!
    </p>
  `,
  person: {
    name: "Jason Max",
    title: "Senior Consultant (Solution Owner), Denver"
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};

const AUDREY_JENSEN = {
  text: `
    <p>
      Amy participated as a platform engineer on the Accommodations Connections Accelerator project, during which she was responsible for delivering infrastructure as code for the data and analytics engine. During the two months I participated on the project, Amy demonstrated a willingness to experiment and grow, especially in areas that most engineers find challenging. This was reflected especially as the AWS presentation loomed, and communicating value to our partner's evaluators became critical. Amy took on the lion's share of the documentation for the project and proved a powerhouse, documenting how each subsystem worked, how they combined to solve customer problems, assumptions made during their creation, and how to adapt each system to client systems. The effort Amy applied to the documentation showed our technical evaluation team that we'd delivered a product capable of being deployed as a baseline in a client environment.
    </p>

    <p>
      Furthermore, during demo preparation, I challenged Amy to be vulnerable with the AWS team, demonstrating how she grew as a developer while interacting with their tools. I was delighted when she ran with that advice, explaining how she used resources to link dependencies between CDK stacks, and how it enabled the system to be developed as a set of flexible, linked components. Amy's willingness to experiment and grow her storytelling skills will benefit her as she steps into more client-facing roles in emerge.
    </p>
  `,
  person: {
    name: "Audrey Jensen",
    title: 'Senior Consultant, Philadelphia'
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};

const RICH_HAM_KUCHARSKI = {
  text: `
    <p>
      Amy is true unsung hero! Paired with Craig to perform the bulk of our AWS work for the Accommodations Connections Accelerator (ACA), Amy has provided the balance of communications, documentation, testing, backlog coordination and more for our team.
    </p>

    <p>
      I am also so pleased to have seen Amy grow in her leadership over her time with the team. She has taken on mentoring new team members to join the project. Helped teach others about the AWS services and tools we are using, and guiding some of the recent development decisions that have occurred as we worked toward completing our MVP to demonstrate to AWS, our client.
    </p>

    <p>
      Amy has created an amazing amount of documentation which will be invaluable to delivery teams that need to use the accelerator for future client deployments. She has also pivoted several times to ensure we could meet some very tight deadlines.
    </p>

    <p>
      The team and I are lucky to have her working with us, and I look forward to seeing what is next as fine tune the solution for future deployments.
    </p>
  `,
  person: {
    name: "Rich Ham-Kucharski",
    title: "Enterprise Architect, Public and Social Impact"
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};

const PETER_ROJANAVONGSE = {
  text: `
    <p>
      It was a pleasure onboarding with Amy - from troubleshooting code syntax to deploying infrastructure-as-code services through AWS CDK. She was patient whenever she worked with me and took the time to explain every detail. Not only that, but she was the best at describing the best practices and why it was necessary to implement the best practices. I wish I could give her more than one reward because it isn't sufficient to describe how she helped me grow individually but also how she lead the onboarding process. I hope to work with her again.
    </p>
  `,
  person: {
    name: "Peter Rojanavongse",
    title: "DevOps Engineer, Washington, D.C."
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};

const JACK_SEXAUER = {
  text: `
    <p>
      I think you have completely spoiled my expectations for future projects about the ability for a Figma design to be translated perfectly into React. Your ability to transform design into product is wizardlike, in my opinion.
    </p>

    <p>
      One of your biggest impacts was spearheading the development of our entire frontend. It is no easy task to be primarily responsible for the front end of a web application, but you did it with grace and set an excellent standard for the type of work the frontend would require. I think you did a great job at designing frontend components in a way that were both functionally supportive of the project's objectives and accessible to end users.
    </p>

    <p>
      There were a couple times that I had ideas that I wasn't entirely sure how to communicate, and you helped amplify my voice by asking questions and being willing to experiment with new configurations, even though my thoughts were still developing. I think that ability to remain flexible and open to new thoughts and ideas will continue to serve you moving into the future.
    </p>
  `,
  person: {
    name: "Jack Sexauer",
    title: "Consultant (Engineer), Chicago"
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};

const YASHIL_KALYANI = {
  text: `
    <p>
      Amy is leading the design and front-end aspects of the DART application. She has molded herself into the go-to person for all things design and front-end. Her expertise in React and web design is unparalleled. I believe that if someone can explain and teach a concept to others, they truly understand that concept.
    </p>

    <p>
      Amy guides team members to meet and exceed delivery expectations. She has also been instrumental in creating the backend of the DART application. She has developed many RESTful APIs that directly improve the functionality of the application.
    </p>

    <p>
      Amy also guides work (self & others) for prioritization, quality, and accuracy. She has conducted multiple peer review/demo sessions, for the design of the DART application, where she discusses the design with the team members, gathers feedback, and creates an excellent user experience. 
    </p>

    <p>
      She has set up several peer programming sessions to teach the cloud residents React concepts and ensured that the team is on the right track with the deliverables. Moreover, Amy proactively removes obstacles for her team members and respectfully facilitates multiple points of view. She flexes her natural style and approach to accommodate and thoughtfully understand others' perspectives. She asks powerful questions to encourage critical thinking and a growth mindset. 
    </p>

    <p>
      For instance, while creating support for Questions in the DART application, she provided me with a base to kickstart my progress with this ticket. She promptly resolved my queries, provided insightful feedback on my code (as she did with several other pull requests), and asked intelligent questions which challenged my assumptions. This resulted in a high-quality deliverable from my end.
    </p>

    <p>
      Amy provides and solicits timely, specific feedback, both positive and corrective. I am amazed by how attentive she is to the performance of her peers. As I was approaching the end of my time with the Byte Builders team, I requested her feedback. She was happy to jump on a call with me and communicated what she thought were my areas of strength v/s improvement. The feedback clarified what I was doing well and what I should change. This is invaluable for an early-career individual like me.
    </p>

    <p>
      Amy has been the best mentor I could have asked for during this project. During my conversations with other residents, this conclusion has been the consensus. She is passionate about mentoring others, and it is evident in her day-to-day.
    </p>
  `,
  person: {
    name: "Yashil Kalyani",
    title: "Consultant (Engineer), Boston"
  },
  project: {
    prefixText: "for work at",
    link: "https://www.slalom.com/",
    name: "Slalom"
  }
};


const ALEX_BRYCE = {
  text: `
    <p>
      Matt and Amy have been amazing developers to work with. They're thorough in their work, clear and responsive in communication, and you can tell that they genuinely care about the software they create.
    </p>
    <p>
      In their time spent working with us, they built a highly interactive and functional website for us to train our clients remotely and we have no doubt that it will be a hit with live users once we launch.
    </p>
    <p>
      We're excited to keep working with them in the future and I'd recommend them highly for any kind of software or development position.
    </p>
  `,
  person: {
    name: "Alex Bryce",
    title: `Personal Trainer &amp; Co-Founder`,
  },
  project: {
    prefixText: "for work on",
    link: "https://electrumperformance.com/",
    name: "Electrum Performance"
  }
};

const JEN_YIP = {
  text: `
    <p>
      Anyone who has read my blog posts might know that I have a strong aversion to paying for services. Well, I can say that for me, ProfitKit is 100% worth it.
    </p>

    <p>
      I've tried many subscription analytics solutions and they all spewed out different numbers. Worst yet, their customer service would just tell me I'm out of luck because I use coupons a certain way.
    </p>

    <p>
      Working with Matt &amp; Amy has been awesome because they have made their product customizable to everyone's unique needs and I now have full confidence in the numbers I see on my dashboard.
    </p>
  `,
  person: {
    name: "Jen Yip",
    title: `Founder of <a href="https://lunchmoney.app/">Lunch Money</a>`,
  },
  project: {
    prefixText: "for work on",
    link: "https://www.profitkit.io",
    name: "ProfitKit"
  }
};

const KEVIN_WILKE = {
  text: `
    <p>
      This solved a HUGE problem we had. We are an agency with clients on a monthly billing cycle but had no way to forecast our upcoming revenue on a weekly basis.
    </p>
    <p>
      Our attempt at doing it manually using a spreadsheet was cumbersome and inaccurate. ProfitKit saved us hours each week and ensured the accuracy of our weekly revenue projections.
    </p>
  `,
  person: {
    name: "Kevin Wilke",
    title: `Co-Founder of <a href="https://www.provenmarketingsolutions.co/">Proven Marketing Solutions</a>`
  },
  project: {
    prefixText: "for work on",
    link: "https://www.profitkit.io",
    name: "ProfitKit"
  }
};

const ABINAYA = {
  text: `
    <p>
      Folks, check out ProfitKit, a must need tool for any SaaS business. At Remote Leaf, we faced a huge problem with user payments failing, and I've been manually reminding users to update their subscriptions. Then I found out about ProfitKit through Amy on Indiehackers. Amy was super helpful in setting up the payment recovery integration and also wrote custom development code to fit my product's specific needs.
    </p>

    <p>
      Highly recommend it!
    </p>
  `,
  person: {
    name: "Abinaya",
    title: `Founder of <a href="https://remoteleaf.com/">Remote Leaf</a> `
  },
  project: {
    prefixText: "for work on",
    link: "https://www.profitkit.io",
    name: "ProfitKit"
  }
};

const CHAD_SAKONCHICK = {
  text: `
    <p>
      Only a few days after using ProfitKit, the automatic emails to unpaid subscribers reactivated 4 customers for $40 in MRR and collected $160 in past due payments. I can't think of another piece of software I've used that has paid for itself so directly.
    </p>
  `,
  person: {
    name: "Chad Sakonchick",
    title: `Co-Founder of <a href="https://betterlegal.com/"> BetterLegal</a>`
  },
  project: {
    prefixText: "for work on",
    link: "https://www.profitkit.io",
    name: "ProfitKit"
  }
};

const CLAIRE_AND_GIA = {
  text: `
    <p>
      Amy, thank you so much for stepping in to help with the Forget the Funnel site like you have and putting all of this documentation together. Again, we are more appreciative of this help than you could possibly know.
    </p>

    <p>
      We SUUUUPER appreciate your continued generosity + advice on hiring! Between the Memberstack team themselves + their community of experts, we've found someone to assist us and take over. Thank you so so much for taking the time to do this.
    </p>
  `,
  person: {
    name: "Claire Suellentrop & Georgiana Laudi",
    title: `Professional Marketers &amp; Founders`
  },
  project: {
    prefixText: "for work on",
    link: "https://www.forgetthefunnel.com/",
    name: "Forget the Funnel"
  }
};

const TESTIMONIALS = [
  DAVID_FRIGERI,
  STEPHEN_NICHOLS,
  RAPH_CASADEI,
  AHAD_HOSSEINI,
  JASON_MAX,
  AUDREY_JENSEN,
  RICH_HAM_KUCHARSKI,
  PETER_ROJANAVONGSE,
  JACK_SEXAUER,
  YASHIL_KALYANI,
  ALEX_BRYCE,
  JEN_YIP,
  KEVIN_WILKE,
  ABINAYA,
  CHAD_SAKONCHICK,
  CLAIRE_AND_GIA
];

export default TESTIMONIALS;

