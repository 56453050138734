import React from "react";
import Masonry from 'react-masonry-css'
import TESTIMONIALS from "../data/testimonials";
import styles from "../styles/testimonials.module.scss";
import cleanHtml from "../utils/cleanHtml";

const BREAKPOINT_COLUMN_MAPPING = {
  default: 2,
  600: 1
};

export default function Testimonials() {
  return (
    <div>
      <h1 className={ `header` }>Testimonials</h1>
      <Masonry
        breakpointCols={BREAKPOINT_COLUMN_MAPPING}
        className={ styles.testimonials__masonry }
        columnClassName={ styles.testimonials__column }
      >
        { 
          TESTIMONIALS.map((testimonial) => {
            return (
              <div 
                key={testimonial.person.name} 
                className={ styles.testimonial }
              >
                <div className={ styles.testimonial__body }>
                  <div 
                    className={ styles.text }
                    dangerouslySetInnerHTML={{ __html: cleanHtml(testimonial.text) }}
                  />
                  <div className={ styles.quote__circle }>
                    <div className={ styles.quote }>&#8221;</div>
                  </div>
                </div>

                <div className={ styles.testimonial__footer }>
                  <div className={ styles.name }>
                    { testimonial.person.name }
                  </div>
                  <div
                    className={ styles.role }
                  >
                    <b dangerouslySetInnerHTML={{ __html: cleanHtml(testimonial.person.title )}} />
                    {" "}
                    &mdash; 
                    {" "}
                    <i>{ testimonial.project.prefixText }</i>
                    {" "}
                    <a
                      href={ testimonial.project.link }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={ styles.label }>{ testimonial.project.name }
                    </a>
                  </div>
                </div>
              </div>
            );
          })
        }
      </Masonry>
    </div>
  );
};
